@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Semibold";
  src: local("Montserrat-Semibold"),
    url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Flex";
  src: local("Roboto-Flex"),
    url(./assets/fonts/Roboto_Flex/RobotoFlex-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#advice{
  font-family: 'Roboto' !important;
}

#linkAIEPBA{
  font-family: 'Montserrat-Bold' !important;
}

#navLink{
  font-family: 'Montserrat-Medium' !important;
  font-weight: 500;
}
#adDetail{
  font-family: 'Montserrat-Medium' !important;
  font-weight: 500;
}

